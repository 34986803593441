import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import {
  propTypes,
  SHOW_SEARCH_BAR,
  SIGNUP_TAB_DETAIL,
  SIGNUP_TAB_COMPLETED,
  BUSINESS_LISTING_TYPE_PUBLISHED,
  BUSINESS_LISTING_TYPE_DRAFT,
} from '../../../../util/types';
import { getBusinessListingParams } from '../../../../util/data';
import { FormattedMessage, intlShape } from '../../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';

import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  Button,
} from '../../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    intl,
    className,
    appConfig,
    onLogout,
    currentUser,
    currentPage,
    onSearchSubmit,
    businessListing,
    isAuthenticated,
    rootClassName,
    notificationCount,
    initialSearchFormValues,
    onResendVerificationEmail,
    sendVerificationEmailInProgress,
  } = props;

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      currentSearchParams={initialSearchFormValues}
      appConfig={appConfig}
      currentPage={currentPage}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const { emailVerified, profile } = (currentUser && currentUser.id && currentUser.attributes) || {};
  const { publicData, protectedData } = profile || {};
  const { isAdmin } = publicData || {};
  const {
    adminPanel,
    signUpCompleted,
    algoliaSearch = false,
    businessListingId = false,
    businessListingPublished = false,
  } = protectedData || {};
  const { pageCompleted } = (businessListing && businessListing.id && businessListing.attributes.privateData) || {};
  const editListingParams = getBusinessListingParams(currentUser);
  const showSearch = algoliaSearch && SHOW_SEARCH_BAR.includes(currentPage);
  const inboxLink = authenticatedOnClientSide
    ? !signUpCompleted || (signUpCompleted && signUpCompleted != SIGNUP_TAB_COMPLETED)
      ? <NamedLink className={css.navLink} name="SignUpStepsPage" params={{ tab: "detail" }} >
        <span className={css.createListing}>
          <FormattedMessage id={signUpCompleted == SIGNUP_TAB_DETAIL
            ? 'TopbarDesktop.profileDetail'
            : 'TopbarDesktop.profileReference'} />
        </span>
      </NamedLink>
      : signUpCompleted && signUpCompleted == SIGNUP_TAB_COMPLETED
        ? emailVerified
          ? businessListingId
            ? businessListingPublished == BUSINESS_LISTING_TYPE_PUBLISHED
              ? 
              <>
              <NamedLink
                className={css.rfpOwnerLink}
                name="DashboardBasePage"
                params={{ tab: "owner" }}
              >
                <span className={css.rfpText}>
                  <FormattedMessage id="TopbarDesktop.rfpOwner" /> {notificationDot}
                </span>
              </NamedLink>
              <NamedLink
                className={css.rfpLink}
                name="ProviderDashBoardPage"
                params={{ tab: "provider" }}
              >
                <span className={css.rfpText}>
                  <FormattedMessage id="TopbarDesktop.rfpProvider" /> {notificationDot}
                </span>
              </NamedLink>
              </>
              : pageCompleted == 'details'
                ? <NamedLink className={css.navLink} name="EditListingPage" params={editListingParams}>
                  <span className={css.createListing}>
                    <FormattedMessage id={"TopbarDesktop.businessDetail"} />
                  </span>
                </NamedLink>
                : pageCompleted == 'finalStep'
                  ? <NamedLink className={css.navLink} name="EditListingPage" params={editListingParams}>
                    <span className={css.createListing}>
                      <FormattedMessage id={"TopbarDesktop.businessFinal"} />
                    </span>
                  </NamedLink>
                  : <NamedLink className={css.navLink} name="StripePayoutPage" >
                    <span className={css.createListing}>
                      <FormattedMessage id={"TopbarDesktop.addBankDetails"} />
                    </span>
                  </NamedLink>
            : <NamedLink className={css.navLink} name="EditListingPage" params={editListingParams}>
              <span className={css.createListing}>
                <FormattedMessage id={"TopbarDesktop.businessAdd"} />
              </span>
            </NamedLink>
          : <Button
            className={css.buttonLink}
            onClick={onResendVerificationEmail}
            inProgress={sendVerificationEmailInProgress}
          >
            <span className={css.createListing}>
              <FormattedMessage id={"TopbarDesktop.verifyEmail"} />
            </span>
          </Button>
        : null
    : null;

  // const createEvent = ((businessListingId && businessListingPublished == BUSINESS_LISTING_TYPE_PUBLISHED)
  //   ? <NamedLink className={css.navLink} name="NewListingPage">
  //     <span className={css.createListing}>
  //       <FormattedMessage id="TopbarDesktop.createRFP" />
  //     </span>
  //   </NamedLink>
  //   : null);

  const menuItems = [];

  if (businessListingId && businessListingPublished == BUSINESS_LISTING_TYPE_PUBLISHED) {
    menuItems.push({
      key: "NewListingPage",
      id: "TopbarDesktop.createRFP",
      css: classNames(css.yourListingsLink, currentPageClass('NewListingPage')),
      pageCss: "NewListingPage",
      type: "NamedLink",
      name: "NewListingPage",
    });
  }

  if (isAdmin || adminPanel) {
    menuItems.push({
      key: "AdminDashboardPage",
      id: "TopbarDesktop.adminDashboardLink",
      css: classNames(css.yourListingsLink, currentPageClass('AdminDashboardPage')),
      pageCss: "AdminDashboardPage",
      type: "NamedLink",
      name: "AdminDashboardBasePage",
    });
  }

  if (businessListingId || (!signUpCompleted || (signUpCompleted && signUpCompleted != SIGNUP_TAB_COMPLETED))) {
    menuItems.push({
      key: "EditListingPage",
      id: "TopbarDesktop.myBusiness",
      css: classNames(css.yourListingsLink, currentPageClass('EditListingPage')),
      pageCss: "EditListingPage",
      type: "NamedLink",
      name: (businessListingId ? "EditListingPage" : "SignUpStepsPage"),
      params: (businessListingId ? editListingParams : { tab: "detail" })
    });
  }
  

  menuItems.push(...[
    {
      key: "AccountSettingsPage",
      id: "TopbarDesktop.accountSettingsLink",
      css: classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage')),
      pageCss: "AccountSettingsPage",
      type: "NamedLink",
      name: "AccountSettingsPage",
    },
    {
      key: "logout",
      id: "TopbarDesktop.logout",
      css: classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage')),
      type: "Button",
      fn: onLogout,
    }]);

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        {menuItems.map(m => (<MenuItem key={m.key}>
          {m.type == "NamedLink"
            ? <NamedLink
              className={classNames(m.css, currentPageClass(m.pageCss))}
              name={m.name}
              params={m.params || {}}
            >
              {m.key == 'InboxPage' && notificationDot
                ? <span className={css.inbox}>
                  {notificationDot}
                </span>
                : <span className={css.menuItemBorder} />}
              <FormattedMessage id={m.id} />
            </NamedLink>
            : m.type == "Button"
              ? <InlineTextButton rootClassName={css.logoutButton} onClick={m.fn}>
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.logout" />
              </InlineTextButton>
              : null}
        </MenuItem>))}
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <LinkedLogo
        className={css.logoLink}
        layout="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
      />
      {showSearch ? search : <div className={css.searchLink} />}

      {/* {createEvent} */}
      {inboxLink}
      {profileMenu}
      {signupLink}
      {loginLink}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
